// |--------------------------------------------------------------------------
// | Element toggler
// |--------------------------------------------------------------------------
// |
// | This jQuery script is written by
// |
// | Morten Nissen
// | hjemmesidekongen.dk
// |
var elementToggler = (function ($) {
    'use strict';

    var pub = {};

    /**
     * Instantiate
     */
    pub.init = function (options) {
        registerEventHandlers();
        registerBootEventHandlers();
    };

    /**
     * Register boot event handlers
     */
    function registerBootEventHandlers() {}

    /**
     * Register event handlers
     */
    function registerEventHandlers() {

        // Toggle drawer
        $('[data-toggle-element]').on('click', function(event) {
            event.preventDefault();
            var identifier = $(this).attr('data-toggle-element'),
                $element = $(identifier);

            $element.toggleClass('open');
        });
    }

    return pub;
})(jQuery);
