// Document ready
(function ($) {
    'use strict';

    // Enable layout
    layout.init();

    // Enable element toggler
    elementToggler.init();

    // Enable Bootstrap tooltips
    if (Modernizr.touchevents) {
        $('[data-toggle=tooltip]').tooltip('hide');
    }
    else {
        $('[data-toggle=tooltip]').tooltip();
    }

    // Enable Bootstrap carousel
    $('.carousel').carousel();

    // Modal
    $('.analysis-link').on('click', function(event) {
        event.preventDefault();

        var $element = $(this),
            modalTarget = $element.hasClass('analysis-link__info') ? '#waterworkInformationModal' : '#waterworkGraphModal',
            href = $element.attr('href');

        $(modalTarget)
            .modal('show')
            .find('.modal-content')
            .load(href);
    });

    // Waterplant information toggle
    $('[data-toggle-waterplant]').on('click', function(event) {
        event.preventDefault();

        $('body').toggleClass('page--wp-injected-visible');
    })

})(jQuery);
